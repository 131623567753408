import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

import infographic from '../../images/how_it_works.jpg';


const HowItWorks = props => (
  <Layout>
    <SEO title="How It Works" />
    <section className="howitworks" id="howitworks">
      <div className="howitworks-content">
        <img className="howitworks-content-image" alt="infographic" src={infographic} />
        <div className="howitworks-content-text">
          <p className="howitworks-content-text-title">HOW IT WORKS</p>
          <p className="howitworks-content-text-body">Edduus is the first-of-its-kind learning system incorporating the Edduus AMM™, that makes learning personalised, engaging and credible for everyone.</p>
          <p className="howitworks-content-text-body">The AMM™ system uses AI technologies to synthesize data from information sources, while weighing context to enable adaptive on-demand learning, personalized to each learners needs, skills, personality and interests.</p>
          <p className="howitworks-content-text-body">Anyone now can access a learning environment that intelligently adapts to them, delivering “just right”, just in time, learning and social connections.</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default HowItWorks;
